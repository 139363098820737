.Invoice {
  max-width: 900px;
  font-size: 14px;
  user-select: none;
}

@media screen {
  .Invoice {
    padding: 25px;
  }
}

@media print {
  .Invoice {
    padding: 0 15px;
    font-size: 11px;
  }
}

hr {
  border: 0 none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #ccc;
}

.Invoice-header {
  position: relative;
  text-align: center;
}

.Logo {
  text-transform: uppercase;
  font-weight: 200;
  font-size: 32px;
  cursor: pointer;
  user-select: none;
  margin: 0;
}

.Logo-part-1 {
  color: #666;
}

.Logo-part-2 {
  font-weight: 500;
  color: #000;

}

.Header-meta {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  line-height: 12px;
  text-align: right;
}

.Invoice-body {
}

.Invoice-subjects {
  padding-top: 10px;
}

.Invoice input {
  width: 100%;
  box-sizing: border-box;
  padding: 2px 5px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 3px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.Invoice input.Half-size {
  width: 50%;
}

.Invoice-subject small {
  text-transform: uppercase;
  font-weight: 200;
}

.Invoice-subject {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  box-sizing: border-box;
}

.Subject-provider {
  padding-right: 15px;
}

.Subject-customer {
  padding-left: 15px;
}

.Invoice-the-invoice {
}

.Invoice-meta {
}

.Invoice-details {
}

.Invoice-footer {
  text-align: center;
  margin: 25px 0 0 0;
  font-size: 11px;
  white-space: nowrap;
}

@media print {
  .Invoice-footer {
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 25px;
    right: 25px;
  }
}

.Invoice-table {
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.Invoice-table th {
  background-color: #f5f5f5;
}

.Invoice-table td,
.Invoice-table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  line-height: 18px;
  padding: 5px 10px;
}

@media print {
  .Invoice-table td,
  .Invoice-table th {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    line-height: 14px;
    padding: 3px 7px;
  }

}

.Table-wide {
  width: 100%;
}

.Invoice-table .Table-totals {
  padding: 0;
}

.Invoice-table .Totals-table {
  width: 100%;
  border-collapse: collapse;
  border: 0 none;
}

.Invoice-table .Totals-table th {

}

.Invoice-table .Totals-table th,
.Invoice-table .Totals-table td {
  border: 0 none;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  text-align: right;
}

.Invoice-table .Totals-table tr:first-child th,
.Invoice-table .Totals-table tr:first-child td {
  border-top: none;
}

.Invoice-table .Totals-table th:first-child,
.Invoice-table .Totals-table td:first-child {
  border-left: none;
}

.Col-qty {
  text-align: center;
}

.Col-rate {
  text-align: right;
}

.Col-total {
  text-align: right;
}

/* Invoice-history */
.Invoice-history {
  position: fixed;
  top: 0;
  right: 0;
  width: 356px;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background-color: #333333;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.Invoice-history-header {
  flex-grow: 0;
  padding: 10px;
  border-bottom: 1px solid #666;
}

.Invoice-history-header h2 {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  font-size: 15px;
}

.Invoice-history-body {
  flex-grow: 1;
  overflow-y: auto;
}

.Invoice-history-button {
  border-radius: 2px;
  background-color: whitesmoke;
  color: #333;
  display: inline-block;
  padding: 3px 9px;
  cursor: pointer;
}

.Invoice-history-small-button {
  border-radius: 2px;
  background-color: whitesmoke;
  color: #333;
  display: inline-block;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
}

.Invoice-history-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Invoice-history-item {
  margin: 0;
  padding: 0;
  padding: 10px;
  border-bottom: 1px solid #666;
  display: flex;
  flex-direction: row;
}

.Invoice-history-row-head {
  flex-grow: 1;
}
.Invoice-history-row-actions {
  flex-grow: 0;
}
